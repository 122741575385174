import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const PURCHASE_RETURN_INFO = {
  formTitle: "Purchase Order",
  path: "/purchaseorders",
  subPath: "/purchasereturns",
  listPage: "/purchase-orders",
}

export const PURCHASE_RETURN_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "purchaseOrder",
      fieldItems: [
        {
          label: "PO Number",
          type: "text",
          name: "purchaseOrderNumber",
          initialFocus: true,
          disabled: true,
          defaultValue: `PO-.YYYY.-`
        },
        {
          label: "PO Date",
          type: "date",
          name: "purchaseOrderDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "PO date is required"
          }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   disabled: true,
        //   muliselect: false,
        //   url: "/users"
        // }
      ]
    },
    tableForm: {
      sectionType: "Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: true,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect

              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Return Qty"}
                required={false}
              />
            )
          },
          accessor: "returnQty",
          width: 80,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"returnQty"}
              rowNumber={row.id}
              valueType={"number"}
              align="right"
              disabled={row.isDisable}
              headerName={"Return Qty"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unit Cost"}
                required={false}
              />
            )
          },
          accessor: "unitCost",
          width: 80,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"unitCost"}
              rowNumber={row.id}
              valueType={"number"}
              align="right"
              disabled={row.isDisable}
              headerName={"Unit Cost"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Returned By"}
                required={false}
              />
            )
          },
          accessor: "returnedBy",
          width: 90,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"returnedBy"}
              rowNumber={row.id}
              validationProps={false}
              disabled={true}
              align="left"
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Return Date"}
                required={true}
              />
            )
          },
          accessor: "returnDate",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Return date is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableDatePicker
              fieldName={"items"}
              fieldItemName={"returnDate"}
              rowNumber={row.id}
              value={row.value}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Return Date"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: "Subtotal (ETB)",
          accessor: "subTotal",
          width: 80,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (
              <FormTableCalculateDisplay
                fieldName={"items"}
                rowNumber={row.id}
                calculateType="multiply"
                valuesToCalculate={["returnQty", "unitCost"]}
              />
            )
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    totalDetails: {
      sectionType: "Total",
      sectionTypeId: "totalDetails",
      multiForm: false,
      expandable: false,
      isExpand: true,
      addButton: true,
      position: "LAST",
      breakPoint: 0,
      fieldName: "total",
      fieldItems: [
        {
          label: "Subtotal",
          type: "text",
          name: "subTotal",
          initialFocus: false,
          disabled: true
        }
      ],
    }
  },
  watchFields: []
}
