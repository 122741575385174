import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import IconArrowDown from "../../assets/icons/down-arrow.svg";
import IconArrowDownWhite from "../../assets/icons/down-arrow-white.svg";
import { IoIosArrowDown } from "react-icons/io";

import { setExportData } from '../../redux/export/export.actions';
import { selectExportData } from '../../redux/export/export.reselect';
import exportDataToExcel from '../../utils/export-data-to-excel.util';
import { exportDataToPdf } from '../../utils/export-print-data-to-pdf.util';

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';
import ExportCSV from '../export-csv/export-csv.componenet';

const ReportDateRangeButton = ({
    DropDownButton,
    handleClick,
    inverted
}) => {
    const [isCSVClicked, setIsCSVClicked] = useState(false);

    return (
        <>
            <DropDownContainer
                DropDownButton={DropDownButton}
                dropDownItems={[]}
                iconLeft={IoIosArrowDown}
                size="medium"
                dropDwon={false}
                inverted={inverted}
                handleClick={(event) => { handleClick(event) }}
            >
                Date Range
            </DropDownContainer>
        </>
    )
}

export default ReportDateRangeButton