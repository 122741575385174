import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { createStructuredSelector } from "reselect";
import fetchSelect from "../../effects/fetch-select";
import useFetchDefault from "../../effects/use-fetch-default";
import { setValueEntredBlur } from "../../redux/form/form.actions";
import { selectFormEdit } from "../../redux/form/form.reselect";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import './select-field.componenet.scss';

const SelectField = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    partNumber,
    fieldItemName,
    validationProps,
    authToken,
    endpointUrl,
    selectEdit,
    mutliselect,
    disabled,
    defaultValue,
    isDefaultValueFromApi,
    inverted,
    isNotArrayField,
    isSearchable,
    setValueEntredBlur,
    hasDefaultValue,
    defaultValueUrl,
    formEdit,
    ...otherProps
}) => {
    const [defaultValueFromApi, setDefaultValueFromApi] = useState("");

    const { defaultData, defaultError } = useFetchDefault(defaultValueUrl, authToken, hasDefaultValue);

    const customStyles = {
        control: (styles) => ({
            ...styles,
            fontFamily: '"Roboto", sans-serif',
            border: '1px solid #E9E9E9',
            height: '33.5px',
            minHeight: '33.5px',
            backgroundColor: disabled ? '#FAFAFA' : inverted ? '#FAFAFA' : '#FAFAFA',
            color: disabled ? '#888777' : '#212529',
            textIndent: '10px',
            borderRadius: '10px',
            fontSize: '14px',
            boxShadow: "none",
            textTransform: "capitalize",
            pointerEvents: "auto",
            "&:focus-within": {
                borderColor: "#3084B7"
            },
            "&:hover": {
                cursor: disabled ? "not-allowed" : "pointer"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            color: "red",
            fontSize: "14px",
            backgroundColor: state.isSelected ? "#F4F5F6" : "",
            borderRadius: "7px",
            marginTop: "3px",
            textTransform: "capitalize",
            color: state.isSelected ? "#111" : null,
            "&:hover": {
                backgroundColor: "#F4F5F6",
            },
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.7 : 1;
            const color = state.isDisabled ? '#000' : "";
            const transition = 'opacity 300ms';
            return {
                ...provided,
                opacity,
                transition,
                color,
            };
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const { control, register, formState: { errors }, setValue } = useFormContext();

    const name = isNotArrayField ? fieldName : fieldName + '.' + partNumber + '.' + fieldItemName;

    const valueEntered = () => {
        setValueEntredBlur(true)
    }

    const loadOptions = (inputValue) => {
        const options = new Promise((resolve, reject) => {
            resolve(fetchSelect(endpointUrl, authToken, inputValue))
        });
        return options;
    };

    useEffect(async () => {
        if (isDefaultValueFromApi) {
            const loadOp = await loadOptions("");
            setDefaultValueFromApi(loadOp[0]);
        }
    }, [])

    useEffect(() => {
        if (!formEdit && Object.keys(defaultData).length && !defaultData.error) {
            setValue(name, defaultData)
        } else if (!formEdit) {
            setValue(name, defaultValue)
        }
    }, [defaultData])

    return (
        <div className='select-field'>
            {
                label &&
                <label
                    className="select-field__label">
                    {label}
                    {
                        validationProps ?
                            validationProps.required ? <span className="form-field__label__required"> * </span> :
                                null :
                            null
                    }
                </label>
            }

            <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                defaultValue={defaultData.length ? defaultData : defaultValue}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                }) => {
                    return (
                        <AsyncSelect
                            className={`select-field__select ${errors[fieldName]?.[partNumber]?.[fieldItemName] && 'select-field__select--error'}`}
                            styles={customStyles}
                            defaultOptions={true}
                            cacheOptions={true}
                            value={value ? value : defaultValueFromApi}
                            onChange={(value) => {
                                onChange(value);
                                valueEntered();
                            }}
                            loadOptions={loadOptions}
                            isMulti={mutliselect}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            isDisabled={disabled}
                            isSearchable={isSearchable}
                            onBlur={(event) => {
                                valueEntered();
                            }}
                            autoComplete="new-password"
                        />
                    )
                }}
            />
            {errors?.[fieldName]?.[partNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[partNumber]?.[fieldItemName].message} />
            }
        </div >
    )
};

const mapStateToProps = createStructuredSelector({
    formEdit: selectFormEdit,
});

const mapDispatchToProps = dispatch => ({
    setValueEntredBlur: (data) =>
        dispatch(setValueEntredBlur(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectField);
