import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const PRODUCTION_REPORT_BY_ORDER_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/workorderreport",
  listPage: "/work-order-reports",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
}

export const PRODUCTION_REPORT_BY_ORDER_COLUMN_TEMPLATE = [
  {
    Header: "WO Number",
    accessor: "workOrderNumber",
    Footer: 'Grand Total'
  },
  {
    Header: "Work Order Status",
    accessor: "workOrderStatus",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
        return displayCellValue(value);
      }

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={value}
          />
        </div>
      )
    }
  },
  {
    Header: "Branch",
    accessor: "locationCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Order Date",
    accessor: "workOrderDate",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },

  {
    Header: "Total Cost (ETB)",
    accessor: "totalCost",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Cost: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'totalCost'
  },
]
