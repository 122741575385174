import {createSelector} from 'reselect'

const deactivateSelector = (state) => state.deactivate; 

export const selectDeactivateIsLoading = createSelector(
    [deactivateSelector],
    alert => alert.isLoading
);

export const selectIsDactivated = createSelector(
    [deactivateSelector],
    alert => alert.isDactivated
);

export const selectDeletedItems = createSelector(
    [deactivateSelector],
    alert => alert.deactivated
);
