import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import useOutSideClick from '../../utils/out-side-click.util';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setBurgerHidden } from '../../redux/navigation/navigation.actions';

import './setting-notification-bar.styles.scss';

import NavDropdown from '../nav-dropdown/nav-dropdown.components';
import IconDownArrow from '../../assets/icons/down-arrow.svg';
import IconDownArrowFilled from '../../assets/icons/down-arrow-filled.svg';
import NavDropdownConsistentView from '../nav-dropdown-consistent-view/nav-dropdown-consistent-view.components';
import { selectBurgerHidden } from '../../redux/navigation/navigation.reselect';
import { createStructuredSelector } from 'reselect';


const SettingNotificationBar = ({
    navItem,
    children,
    handleBurgerClick,
    burgerHidden,
    setBurgerHidden
}) => {
    const history = useHistory();

    const [isDropDown, setIsDropDown] = useState(false);
    const navItemRef = useRef(null);

    const handleCollabseDropDown = () => {
        setIsDropDown(false);
    }

    useOutSideClick(navItemRef, handleCollabseDropDown)

    const handleNavClick = () => {
        if (!burgerHidden) {
            setBurgerHidden();
        }

        setIsDropDown(!isDropDown);
    }

    const handleNavClickWithoutDropDown = (event) => {
        history.push(navItem.page);
        setBurgerHidden();
    }

    return (
        <li className="setting-notifcation-bar-item" ref={navItemRef} onClick={navItem.dropDownItems ? handleNavClick : handleNavClickWithoutDropDown}>
            <button
                className="setting-notifcation-bar-item__button"
                data-page={navItem.dropDownItems ? null : navItem.page}
            >
                {children ?
                    children
                    :
                    <img src={navItem.icon} className="setting-notifcation-bar-item__icon" alt="Icon Notification" />
                }
            </button>

            {
                isDropDown &&
                <NavDropdownConsistentView
                    dropDownItems={navItem.dropDownItems}
                    direction={`RIGHT`}
                    gap={"SHORT"}
                    hasIcons={true}
                    sameOnMobileView={true}
                />
            }
        </li>
    )
};

const mapStateToProps = createStructuredSelector({
    burgerHidden: selectBurgerHidden,
});


const mapDispatchToProps = (dispatch) => ({
    setBurgerHidden: () =>
        dispatch(setBurgerHidden()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingNotificationBar);