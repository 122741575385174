import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { FiPrinter } from "react-icons/fi";
import IconPrint from "../../assets/icons/print.svg";
import { selectExportData } from "../../redux/export/export.reselect";
import { printData } from "../../utils/export-print-data-to-pdf.util";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';

const ReportPrint = ({ 
    DropDownButton,
    dataPrint 
}) => {
    const handleClick = () => {
        printData(dataPrint);
    }

    return (
        <DropDownContainer
            DropDownButton={DropDownButton}
            icon={FiPrinter}
            size="medium"
            dropDwon= {false}
            handleClick={handleClick}
        />
    )
}


const mapStateToProps = createStructuredSelector({
    dataPrint: selectExportData,
});

export default connect(mapStateToProps)(ReportPrint)