import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IoIosArrowDown } from "react-icons/io";
import IconArrowDown from "../../assets/icons/down-arrow.svg";
import { setExportData } from '../../redux/export/export.actions';
import { selectExportData } from '../../redux/export/export.reselect';
import exportDataToExcel from '../../utils/export-data-to-excel.util';
import { exportDataToPdf } from '../../utils/export-print-data-to-pdf.util';

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';
import ExportCSV from '../export-csv/export-csv.componenet';

const EXPORT_DROP_DOWN_ITEMS = [
    {
        id: "pdf",
        text: "PDF"
    },
    {
        id: "excel",
        text: "Excel"
    },
    {
        id: "CSV",
        text: "CSV"
    }
]

const ReportExport = ({
    DropDownButton,
    exportData,
}) => {
    const [isCSVClicked, setIsCSVClicked] = useState(false);

    const handleClick = (event) => {
        if (event.target.id === "CSV") {
            setIsCSVClicked(true)
        } else if (event.target.id === "excel") {
            exportDataToExcel(exportData);
        } else if (event.target.id === "pdf") {
            exportDataToPdf(exportData);
        }
    }

    useEffect(() => {
        setIsCSVClicked(false);
    })

    return (
        <>
            <DropDownContainer
                DropDownButton={DropDownButton}
                dropDownItems={EXPORT_DROP_DOWN_ITEMS}
                icon={IoIosArrowDown}
                size="medium"
                dropDwon={true}
                handleClick={(event) => { handleClick(event) }}
            >
                Export
            </DropDownContainer>

            {isCSVClicked && <ExportCSV data={exportData.data} />}
        </>
    )
}


const mapStateToProps = createStructuredSelector({
    exportData: selectExportData,
});

export default connect(mapStateToProps)(ReportExport)