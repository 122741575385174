import { format } from 'date-fns';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

export const STOCK_ADJUSTMENT_COLUMNS_DOCUMENT = [
  {
    Header: "No.",
    id: "count",
    Cell: ({ row }) => Number(row.id) + 1,
    width: 50,
  },
  {
    Header: "Item Code",
    accessor: "itemCode",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Item Name",
    accessor: "itemName",
    width: 200,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Initial Quantity On Hand",
    accessor: "qtyOnHand",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Quantity Change",
    accessor: "qtyChange",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "New Quantity On Hand",
    accessor: "newQtyOnHand",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  }
]