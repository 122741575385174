import { useEffect, useRef, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { addDays, format } from 'date-fns';

import "./report-header.styles.scss";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import ButtonSpinner from "../button-spinner/button-spinner.compoenent";
import DetailRefresh from "../detail-refresh/detail-refresh.component";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import TitleHeader from "../title-header/title-header.componenet";
import ReportExport from "../report-export/report-export.compoenent";
import ReportPrint from "../report-print/report-print.compoenent";
import ReportTimeframeSelect from "../report-timeframe-select/report-timeframe-select.componenet";

import DateRange from "../date-range/date-range.component";
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import ReportDateRangeButton from "../report-date-range-button/report-date-range-button.compoenent";
import CustomButton from "../custom-button/custom-button.componenet";
import { setReportDateRange } from "../../redux/report/report.actions";
import { connect } from "react-redux";
import { getThisWeek } from "../../utils/date.utils";
import { selectReportInfo } from "../../redux/report/report.reselect";
import { createStructuredSelector } from "reselect";
import DateRangeHolder from "../date-range-holder/date-range-holder.component";

const ReportHeader = ({
    handleSideNav,
    reportTitle,
    isLoading,
    hasNoDate,
    setReportDateRange,
    reportInfo
}) => {
    const reportHeaderRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const methods = useForm({
        shouldUnregister: true
    });

    const setStickHeader = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 95) {
            return setIsSticky(true);
        }

        setIsSticky(false)
    }

    const setWindowSize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", setStickHeader);
        window.addEventListener("resize", setWindowSize);

        return () => {
            window.removeEventListener("scroll", setStickHeader);
            window.removeEventListener("scroll", setWindowSize);
        }
    }, []);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [])

    return (
        <div className={`report-header  ${isSticky ? "report-header--sticky" : null}`} ref={reportHeaderRef}>
            <TitleHeader
                hamburger={false}
                handleSideNav={handleSideNav}
            >
                {reportTitle}
            </TitleHeader>
            <div className="report-header__tools">
                <div className='report-header__spinner'>
                    {
                        isLoading &&
                        <ButtonSpinner />
                    }
                </div>
                <div className="report-header__tools__actions">
                    {
                        !isMobile &&
                        <>
                            <ReportExport
                                DropDownButton={DropDownButton}
                            />
                            <ReportPrint
                                DropDownButton={DropDownButton}
                            />
                            <DetailRefresh
                                DropDownButton={DropDownButton}
                            />
                        </>
                    }
                    {
                        reportInfo.hasDateRange &&
                        <DateRangeHolder />
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    reportInfo: selectReportInfo
});

const mapDispatchToProps = (dispatch) => ({
    setReportDateRange: (data) =>
        dispatch(setReportDateRange(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportHeader)