import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { useMediaQuery } from 'react-responsive';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

export const STOCK_ADJUSTMENT_COLUMNS = [
    {
        Header: "Adjustment Number",
        accessor: "adjustmentNumber",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "Branch",
        accessor: "Location",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Reason",
        accessor: "reason",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Adjustment Date",
        accessor: "adjustmentDate",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return (format(new Date(value), 'dd/MM/yyyy'))
        }
    },

]