import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { useMediaQuery } from 'react-responsive';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/items";

export const ACCOUNTS_COLUMNS = [
    {
        Header: "Username",
        accessor: "username",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Phone Number",
        accessor: "phoneNumber",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });
            const isRightAligned = true;

            const newValue = { value: 3, label: value }
            return (
                <div className={isMobileView && isRightAligned ?
                    'list-table__body__row__cell--right-aligned-mobile-cell' : ''}>
                    <TableCellStatus
                        value={newValue}
                        noDot={true}
                    />
                </div>
            );
        }
    }
]