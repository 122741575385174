import React from 'react';

import './form-table-button.styles.scss';

import ButtonSpinner from '../button-spinner/button-spinner.compoenent';

const FormTableButton = ({
    children,
    isLoading,
    inverted,
    handleClick,
    hasLeftMargin,
    Icon,
    ...otherProps
}) => (
    <button
        className={`form-table-button ${isLoading ?
            'form-table-button--disabled' :
            ''}
            ${inverted ?
                'form-table-button--inverted' :
                null}
                ${hasLeftMargin && 'form-table-button--left-margin'}
                `}

        {...otherProps}
        disabled={isLoading}
        onClick={handleClick}
    >
        {Icon ?
            <Icon className="form-table-button__icon" /> :
            null
        }
        <span className="form-table-button__text">{children}</span>
    </button>
);

export default FormTableButton;
