export const LOCAL_FILTER_FIELDS = [
    {
        label: "SO Number",
        type: "text",
        name: "salesOrderNumber",
    },
    {
        label: "Customer",
        type: "text",
        name: "customerName",
    },
    {
        label: "Branch",
        type: "text",
        name: "locationCode",
    },
    {
        label: "Status",
        type: "text",
        name: "statusName",
    }
]