export const LOCAL_FILTER_FIELDS = [
    {
        label: "Transfer code",
        type: "text",
        name: "transferCode",
    },
    {
        label: "From Branch",
        type: "text",
        name: "fromLocationCode",
    },
    {
        label: "To Branch",
        type: "text",
        name: "toLocationCode",
    }
]