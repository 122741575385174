import React, { useEffect, useRef } from 'react';
import clone from 'clone';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { PURCHASE_ORDER_INFO, PURCHASE_ORDER_FORM_TEMPLATE } from './purchase-order.template';
import { RECEIVE_INFO, RECEIVE_FORM_TEMPLATE } from '../../receive/receive-form/receive.template';
import { UNSTOCK_INFO, UNSTOCK_FORM_TEMPLATE } from '../../unstock/unstock-form/unstock.template';
import { PURCHASE_RETURN_INFO, PURCHASE_RETURN_FORM_TEMPLATE } from '../../purchase-return/purchase-return-form/purchase-return.template';
import { PURCHASE_ORDER_DOCUMENT_DROP_DOWN_ITEMS } from './purchase-order-documents-nav.template';

import FormContainer from '../../../components/form/form.container';
import { selectFormSideBarNavActiveItem, selectFormTableLatestData, selectFormTableLatestRows, selectFormTableRows, selectFormTemplate, selectSelectedField, selectShowAddPurchaseOrderForm } from '../../../redux/form/form.reselect';
import { resetErrorMessageAndIsFail, setEditDataForm, setFormChangedValues, setFormEdit, setFormInfo, setFormProgress, setFormSideBarNav, setFormSideBarNavActiveItem, setFormTableData, setFormTableLatestData, setFormTableLatestRows, setFormTemplate, setFormTotalValues, setFormType, setFromValidationError, setSelectedField, setSelectedFieldValue, setValueEntredBlur } from '../../../redux/form/form.actions';
import { useState } from 'react';
import { removeEmptyValuedObjects } from '../../../utils/object-values.util';
import { removeObjectWithoutSpecifiedPropertiesHasValue } from '../../../utils/array-values.utils';
import { formatNumberToTwoDecimal, roundNumberTwoDecimal, stringToNumber, stringToNumberWithTwoDecmialPoint } from '../../../utils/format-number.util';
import { withRouter } from 'react-router-dom';
import useFetchEdit from '../../../effects/use-fetch-edit.effect';
import { authTokenSelector, selectAccessRights } from '../../../redux/user/user.reselect';
import { selectIsRefresh } from '../../../redux/refresh/refresh.reselect';
import { setDocumentDropDownItems } from '../../../redux/document/document.actions';
import useFetchFieldValues from '../../../effects/use-fetch-field-values';
import { disableFormFields } from '../../../utils/form-values.utils';
import { cloneDeep } from 'lodash';

import { FaShoppingBag } from "react-icons/fa";
import { FaTruckRampBox } from "react-icons/fa6";
import { GiReturnArrow } from "react-icons/gi";
import { TbTruckReturn } from "react-icons/tb";

const APP_NAME = `purchase_order`;

const SUB_ITEMS = [
    {
        text: "Purchase Order",
        icon: FaShoppingBag,
        info: PURCHASE_ORDER_INFO,
        formTemplate: PURCHASE_ORDER_FORM_TEMPLATE
    },
    {
        text: "Receive",
        icon: FaTruckRampBox,
        info: RECEIVE_INFO,
        formTemplate: RECEIVE_FORM_TEMPLATE
    },
    {
        text: "Return",
        icon: GiReturnArrow,
        info: PURCHASE_RETURN_INFO,
        formTemplate: PURCHASE_RETURN_FORM_TEMPLATE
    },
    {
        text: "Unstock",
        icon: TbTruckReturn,
        info: UNSTOCK_INFO,
        formTemplate: UNSTOCK_FORM_TEMPLATE
    }
]

const PurhcaseOrderForm = ({
    match,
    setFormInfo,
    setFormTemplate,
    setFormEdit,
    setFormTableData,
    setFormTableLatestRows,
    formTableLatestRows,
    setFormTotalValues,
    setFormTableLatestData,
    formLatestData,
    authToken,
    setFormChangedValues,
    setEditDataForm,
    isNew,
    isRefresh,
    selectedField,
    setSelectedFieldValue,
    setSelectedField,
    setDocumentDropDownItems,
    setFormSideBarNav,
    setFormSideBarNavActiveItem,
    formSideBarNavActiveItem,
    setFormProgress,
    setValueEntredBlur,
    setFromValidationError,
    resetErrorMessageAndIsFail,
    accessRights
}) => {
    const [isSetField, setIsSetField] = useState(false);
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isViewOnly = useRef(false);

    const endpoint = isNew ?
        `${SUB_ITEMS[formSideBarNavActiveItem].info.path}` :
        !formSideBarNavActiveItem ?
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}` :
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}${SUB_ITEMS[formSideBarNavActiveItem].info.subPath}`;

    const endpointForQuickComplete = `purchaseorders/${match.params.uuid}`

    const authTokenFromState = authToken;
    const { data, error } = useFetchEdit(
        endpoint,
        authTokenFromState,
        !isNew,
        true,
        isRefresh,
        formSideBarNavActiveItem
    );

    const fieldEndpoint = `/itemvalues`;
    const { fieldData, fieldError } = useFetchFieldValues(
        fieldEndpoint,
        authTokenFromState,
        selectedField,
        isNew
    )

    const TABLE_DATA = [{
        id: 0
    }];

    const getItemCount = (latestFormRows) => {
        return latestFormRows.length;
    }

    const getSubTotal = (latestFormRows, keys) => {
        const hasNumerLatestFormRows =
            removeObjectWithoutSpecifiedPropertiesHasValue(latestFormRows, keys)

        return (
            formatNumberToTwoDecimal(hasNumerLatestFormRows.reduce((prevResult, row) => {
                return (
                    prevResult + (stringToNumber(row[keys[0]]) * stringToNumber(row[keys[1]]))
                )
            }, 0))
        )
    }

    const getItemTax = (formLatestData, subTotal) => {
        if (!formLatestData.ItemTax || !subTotal) {
            return 0
        }

        return formatNumberToTwoDecimal(
            ((stringToNumber(subTotal) * stringToNumber(formLatestData.ItemTax.taxRate)) / 100)
        )
    }

    const getShippingCost = (formLatestData) => {
        if (!(formLatestData && formLatestData.shippingCost)) {
            return 0;
        }

        return formatNumberToTwoDecimal(stringToNumber(formLatestData.shippingCost))
    }

    const getGrandTotal = (subTotal, itemTax, shippingCost) => {
        if (!subTotal || !itemTax) {
            return 0
        }

        return (formatNumberToTwoDecimal(stringToNumber(subTotal) +
            stringToNumber(itemTax) +
            stringToNumber(shippingCost)));
    }

    const getPaidAmount = () => {
        if (!(formLatestData && formLatestData.paidAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.paidAmount)
    }

    const getAppliedCreditAmount = (formLatestData) => {
        if (!(formLatestData && formLatestData.appliedCreditAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.appliedCreditAmount);
    }

    const getRemainingAmount = (grandTotal, paidAmount) => {
        if (!(grandTotal)) {
            return 0;
        }

        return stringToNumber(grandTotal) - stringToNumber(paidAmount);
    }

    const getRefundAmount = (formLatestData) => {
        if (!(formLatestData && formLatestData.refundAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.refundAmount);
    }

    const getIssuedCreditAmount = (formLatestData) => {
        if (!(formLatestData && formLatestData.issuedCreditAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.issuedCreditAmount);
    }

    const getBalance = (remainingAmount, refundAmount, issuedCreditAmount) => {
        if (!(remainingAmount)) {
            return 0;
        }

        if (remainingAmount < 0) {
            const afterRefund = roundNumberTwoDecimal(remainingAmount + refundAmount) * -1;
            const afterIssuedCredit = roundNumberTwoDecimal(remainingAmount + issuedCreditAmount) * -1;

            if ((refundAmount <= (remainingAmount * -1) &&
                refundAmount <= afterIssuedCredit) ||
                (issuedCreditAmount <= (remainingAmount * -1) &&
                    issuedCreditAmount <= afterRefund)
            ) {

                return formatNumberToTwoDecimal(
                    (stringToNumber(remainingAmount)) +
                    stringToNumber(refundAmount) +
                    stringToNumber(issuedCreditAmount)
                );
            }
        }

        return formatNumberToTwoDecimal(stringToNumber(remainingAmount));
    }

    const getSubTotalKeys = (formSideBarNavActiveItem) => {
        switch (formSideBarNavActiveItem) {
            case 0:
                return ["qty", "unitCost"];
            case 2:
                return ["returnQty", "unitCost"]
        }
    }

    useEffect(() => {
        if (isNew) {
            const totalValues = {
                subTotal: 0,
                grandTotal: 0
            };

            setFormTotalValues(totalValues);
        }
    }, [])

    useEffect(() => {
        if (formTableLatestRows.length && (
            formSideBarNavActiveItem === 0 ||
            formSideBarNavActiveItem === 2
        )) {
            const latestFormRows = removeEmptyValuedObjects(formTableLatestRows);
            const itemCount = getItemCount(latestFormRows);
            const subTotalKeys = getSubTotalKeys(formSideBarNavActiveItem)
            const subTotal = getSubTotal(latestFormRows, subTotalKeys);
            const itemTax = getItemTax(formLatestData, subTotal);
            const itemTaxLabel = formLatestData.ItemTax && formLatestData.ItemTax.label.replace(/-.*$/, "");

            const shippingCost = getShippingCost(formLatestData);
            const grandTotal = getGrandTotal(subTotal, itemTax, shippingCost);
            const paidAmount = getPaidAmount(formLatestData);

            const appliedCreditAmount = getAppliedCreditAmount(formLatestData);
            const totalPaidAmount = (stringToNumber(paidAmount) + stringToNumber(appliedCreditAmount))
            const remainingAmount = getRemainingAmount(grandTotal, totalPaidAmount);
            const refundAmount = getRefundAmount(formLatestData);
            const issuedCreditAmount = getIssuedCreditAmount(formLatestData);
            const balance = getBalance(remainingAmount, refundAmount, issuedCreditAmount);

            const totalValues = {
                ...(subTotal && itemTaxLabel !== "Excluding Tax" && { subTotal: subTotal }),
                ...(itemTaxLabel && itemTaxLabel !== "Excluding Tax" && { [itemTaxLabel]: itemTax }),
                ...(shippingCost && { shippingCost: shippingCost.toString() }),
                grandTotal
            };

            const changedValues = {
                payment: [{
                    balance,
                    totalPaidAmount
                }]
            }

            setFormTotalValues(totalValues);
            setFormChangedValues(changedValues);
        }
    }, [formTableLatestRows, formLatestData]);

    useEffect(() => {
        if (selectedField) {
            const selectedFieldValue = {
                name: selectedField.name,
                value: fieldData
            }

            setSelectedFieldValue(selectedFieldValue);
            setIsSetField(true);
            setSelectedField(null)
        }
    }, [fieldData]);

    useEffect(() => {
        if (isSetField) {
            setValueEntredBlur(true);
            setIsSetField(false)
        }
    }, [isSetField])

    useEffect(() => {
        let progress;
        if (formSideBarNavActiveItem === 1) {
            const sumup = formTableLatestRows.reduce((prevValue, row) => {
                const qty = prevValue.qty + (row.qty ? parseFloat(row.qty) : 0);
                const qtyReceived = prevValue.qtyReceived + (row.qtyReceived ? parseFloat(row.qtyReceived) : 0);

                return {
                    qty,
                    qtyReceived
                }
            }, { qty: 0, qtyReceived: 0 })

            progress = ((sumup.qtyReceived / sumup.qty) * 100);
        }

        setFormProgress(roundNumberTwoDecimal(progress));
    }, [formTableLatestRows]);

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === APP_NAME && APP_NAME.toLowerCase()
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "view only")) {
            isViewOnly.current = true;
        }
        setIsAccessRightCheked(true)
    }, [])

    useEffect(() => {
        setFormInfo(SUB_ITEMS[formSideBarNavActiveItem].info);

        const formTemplate = clone(SUB_ITEMS[formSideBarNavActiveItem].formTemplate);

        if (isViewOnly.current && Object.keys(formTemplate).length) {
            const bodySections = formTemplate.bodySections;
            disableFormFields(bodySections)
            bodySections.tableForm.hasNoAction = true;
            delete bodySections.tableForm.tableColumns[bodySections.tableForm.tableColumns.length - 1];
        }

        setFormTemplate(formTemplate);
        setDocumentDropDownItems(PURCHASE_ORDER_DOCUMENT_DROP_DOWN_ITEMS);
        setFormTableData(TABLE_DATA);

        if (!isNew) {
            setFormSideBarNav(SUB_ITEMS);
            setFormEdit(true);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFromValidationError(null);
            resetErrorMessageAndIsFail();
        }
    }, [formSideBarNavActiveItem, isViewOnly]);

    useEffect(() => {
        setEditDataForm(data);
    }, [data]);

    useEffect(() => {
        return () => {
            setFormInfo(null);
            setFormTemplate(null);
            setDocumentDropDownItems(null);
            setFormTableData(null);
            setFormEdit(false)
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setEditDataForm([]);
            setSelectedField(null);
            setSelectedFieldValue(null);
            setFormSideBarNav([]);
            setFormSideBarNavActiveItem(0);
        }
    }, [])

    return (
        <div>
            {isAccessRightChecked &&
                Object.keys(PURCHASE_ORDER_FORM_TEMPLATE).length > 0 ?
                <FormContainer
                    endpoint={endpoint}
                    isViewOnly={isViewOnly.current}
                    endpointForQuickComplete={endpointForQuickComplete}
                />
                : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatestRows: selectFormTableLatestRows,
    formLatestData: selectFormTableLatestData,
    authToken: authTokenSelector,
    isRefresh: selectIsRefresh,
    selectedField: selectSelectedField,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem,
    accessRights: selectAccessRights
})

const mapDispatchToProps = (disptach) => ({
    setFormInfo: (formInfo) =>
        disptach(setFormInfo(formInfo)),
    setFormTemplate: (formTemplate) =>
        disptach(setFormTemplate(formTemplate)),
    setDocumentDropDownItems: (dropDownItems) =>
        disptach(setDocumentDropDownItems(dropDownItems)),
    setFormType: (modalType) =>
        disptach(setFormType(modalType)),
    setFormTableData: (tableData) =>
        disptach(setFormTableData(tableData)),
    setFormTotalValues: (tableData) =>
        disptach(setFormTotalValues(tableData)),
    setFormChangedValues: (data) =>
        disptach(setFormChangedValues(data)),
    setFormEdit: (data) =>
        disptach(setFormEdit(data)),
    setEditDataForm: (data) =>
        disptach(setEditDataForm(data)),
    setFormTableLatestRows: (data) =>
        disptach(setFormTableLatestRows(data)),
    setFormTableLatestData: (data) =>
        disptach(setFormTableLatestData(data)),
    setSelectedFieldValue: (data) =>
        disptach(setSelectedFieldValue(data)),
    setFormSideBarNav: (data) =>
        disptach(setFormSideBarNav(data)),
    setFormSideBarNavActiveItem: (data) =>
        disptach(setFormSideBarNavActiveItem(data)),
    setFormProgress: (data) =>
        disptach(setFormProgress(data)),
    setValueEntredBlur: (data) =>
        disptach(setValueEntredBlur(data)),
    setSelectedField: (data) =>
        disptach(setSelectedField(data)),
    setFromValidationError: (errors) =>
        disptach(setFromValidationError(errors)),
    resetErrorMessageAndIsFail: () =>
        disptach(resetErrorMessageAndIsFail()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurhcaseOrderForm));