import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { useMediaQuery } from 'react-responsive';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/salesorders";

export const SALES_ORDERS_COLUMNS = [
    {
        Header: "SO Number",
        accessor: "salesOrderNumber",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "Customer",
        accessor: "customerName",

        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        },
    },
    {
        Header: "Inventory Status",
        accessor: "salesOrderStatus",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });
            const isRightAligned = true;

            if (!value) {
                return displayCellValue(value);
            }
            return (
                <div className={isMobileView && isRightAligned ?
                    'list-table__body__row__cell--right-aligned-mobile-cell' : ''}>
                    <TableCellStatus
                        value={value}
                    />
                </div>
            )
        }
    },
    {
        Header: "Order Date",
        accessor: "salesOrderDate",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return (format(new Date(value), 'dd/MM/yyyy'))
        }
    },
    {
        Header: "Branch",
        accessor: "locationCode",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Payment Status",
        accessor: "paymentStatus",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            if (!value) {
                return displayCellValue(value);
            }

            return (
                <TableCellStatus
                    value={value}
                />
            )
        }
    },
    {
        Header: "Grand Total (ETB)",
        accessor: "grandTotal",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `Total: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
            }


            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Approval",
        accessor: "approvalStatus",
        Cell: ({ value }) => {

            if (!value) {
                return displayCellValue(value);
            }
            return (
                <TableCellStatus
                    value={value}
                />
            )
        }
    },
]