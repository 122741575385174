import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const PURCHASE_REPORT_BY_SUPPLIER_INFO = {
  reportTitle: "Purchasing Reports",
  path: "/purchasereports",
  subPath: "/purchasebysupplier",
  listPage: "/purchase-reports",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
}

export const PURCHASE_REPORT_BY_SUPPLIER_COLUMN_TEMPLATE = [
  {
    Header: "Supplier Code",
    accessor: "supplierCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Total Cost (ETB)",
    accessor: "totalCost",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Cost: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalCost'
  },

]
