import React, { useState } from "react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { createStructuredSelector } from "reselect";
import fetchSelect from "../../effects/fetch-select";
import { setSelectedField } from "../../redux/form/form.actions";
import { authTokenSelector } from "../../redux/user/user.reselect";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import './form-table-select.styles.scss';

const FormTableSelect = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    rowNumber,
    fieldItemName,
    validationProps,
    authToken,
    endpointUrl,
    selectEdit,
    mutliselect,
    disabled,
    setSelectedField,
    defaultValue,
    headerName,
    isMobileView,
    shouldTriggerSelectedField,
    ...otherProps
}) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            backgroundColor: state.isSelected ? "#F4F5F6" : "",
            borderRadius: "7px",
            marginTop: "3px",
            textTransform: "capitalize",
            color: state.isSelected ? "#111" : null,
            "&:hover": {
                backgroundColor: "#F4F5F6",
            },
        }),
        control: (styles) => ({
            ...styles,
            fontFamily: '"Roboto", sans-serif',
            border: '1px solid #FFF',
            height: !isMobileView ? '37px' : "22px",
            minHeight: !isMobileView ? '37px' : "22px",
            marginTop: "0px",
            paddingTop: "0px",
            backgroundColor: '#FFFFFF',
            textIndent: !isMobileView ? '10px' : "0px",
            borderRadius: '0px',
            fontSize: '14px',
            textTransform: "capitalize",
            borderWidth: "0px",
            verticalAlign:  "baseline",
            boxShadow: "none",
            flexWrap: "none",
            "&:focus-within": {
                borderColor: "#3084B7"
            },
            
        }),
        indicatorSeparator: (styles) => ({ display: 'none' }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.7 : 1;
            const color = state.isDisabled ? '#000' : "";
            const transition = 'opacity 300ms';
            return {
                ...provided,
                opacity,
                transition,
                color,
                padding: 0,
            };
        },
        indicatorsContainer: (provided, state) => ({
            ...provided,
          }),
    }

    const { control, register, formState: { errors }, setValue } = useFormContext();
    const name = fieldName + '.' + rowNumber + '.' + fieldItemName;
    const [newValue, setNewValue] = useState(null);

    const loadOptions = (inputValue) => {
        return new Promise((resolve, reject) => {
            resolve(fetchSelect(endpointUrl, authToken, inputValue))
        });
    };

    const onChangeSelect = (value) => {
        if(shouldTriggerSelectedField){
            const selectedField = {
                name,
                value
            }
    
            setSelectedField(selectedField);
        }
    }

    useEffect(() => {
        return () => {
            setSelectedField(null)
        }
    }, [])


    return (
        <div className='form-table-select'>
            <div className={`${isMobileView ? "form-table-select--mobile" : null}`}>
                {
                    isMobileView &&
                    <label className="form-table-select__label">
                        {headerName}
                    </label>
                }
                <Controller
                    control={control}
                    {...register(name, validationProps)}
                    ref={null}
                    defaultValue={defaultValue && defaultValue}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => {
                        return (
                            <AsyncSelect
                                className={`form-table-select__input ${errors[fieldName]?.[rowNumber]?.[fieldItemName] && 'select-field__select--error'}`}
                                styles={customStyles}
                                defaultOptions={true}
                                cacheOptions={true}
                                value={value}
                                onChange={(value) => {
                                    onChangeSelect(value);
                                    onChange(value)
                                    setNewValue(value)
                                }}
                                loadOptions={loadOptions}
                                isMulti={mutliselect}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                isDisabled={disabled}
                            />
                        )
                    }}
                />
            </div>
            {errors?.[fieldName]?.[rowNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[rowNumber]?.[fieldItemName].message} />}
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedField: (data) =>
        dispatch(setSelectedField(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormTableSelect);