import { useEffect } from "react";
import "./checkbox.styles.scss";

const CheckBox = ({
    handleDeactivate,
    children,
    checked,
    disalbed
}) => {

    return (
        <div className="checkbox">
            <label className="checkbox__label">
                <input
                    className="checkbox__field" 
                    type="checkbox"
                    onChange={handleDeactivate}
                    checked={checked}
                    disabled={disalbed}
                />
                <span className="checkbox__label__text">{children}</span>
            </label>
        </div>
    )
}

export default CheckBox;