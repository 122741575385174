import { startCase } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';import { createStructuredSelector } from 'reselect';
import { selectReportData, selectReportSummaryData } from '../../redux/report/report.reselect';

const ReportBarChart = ({
    reportSummaryData,
    dataKeys
}) => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                width={500}
                height={300}
                barWidth={50}
                data={reportSummaryData ? reportSummaryData : ""}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis stroke="#666" dataKey="date" />
                <YAxis stroke="#666" />
                <Tooltip />
                <Legend />
                <Bar name={startCase(dataKeys[1])} barSize={35} barGap={10} dataKey={dataKeys[1]} fill="#82ca9d"/>
            </BarChart>
        </ResponsiveContainer>
    );
}


const mapStateToProps = createStructuredSelector({
    reportSummaryData: selectReportSummaryData
});

export default connect(mapStateToProps)(ReportBarChart)
