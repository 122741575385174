import { displayCellValue } from '../../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SUPPLIERS_COLUMNS = [
    {
        Header: "Supplier Name",
        accessor: "supplierName",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        }
    },
    {
        Header: "Supplier Id",
        accessor: "supplierCode",
    },
    {
        Header: "Supplier Tin No",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `Tin: ${displayCellValue(value)}`;
            }


            return displayCellValue(value);
        }
    },
    {
        Header: "Supplier Phone Number 1",
        accessor: "supplierPhoneNumber1",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Supplier Email",
        accessor: "supplierEmail",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
]