import React from "react";
import { connect } from "react-redux";

import { IoMdRefresh } from "react-icons/io";
import IconRefresh from "../../assets/icons/refresh.svg";
import { setRefresh } from "../../redux/refresh/refresh.actions";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';

const DetailRefresh = ({ 
    DropDownButton, 
    setRefresh
}) => {
    const handleClick = () => {
        setRefresh(true);
    }

    return (
        <DropDownContainer
            DropDownButton={DropDownButton}
            icon={IoMdRefresh}
            size="medium"
            dropDwon={false}
            handleClick={handleClick}
        />
    )
}

const mapDispatchToProps = (dispatch) => ({
    setRefresh: (data) =>
        dispatch(setRefresh(data)),
})

export default connect(null, mapDispatchToProps)(DetailRefresh)