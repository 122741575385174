import React from 'react';

import './custom-button.styles.scss';

import ButtonSpinner from '../button-spinner/button-spinner.compoenent';

const CustomButton = ({
    children,
    isLoading,
    isComplete,
    isPay,
    inverted,
    handleClick,
    icon,
    ...otherProps
}) => {
    const Icon = icon;
    return (
        <button
            className={`custom-button ${isLoading || isComplete || isPay === "paid" ?
                'custom-button--disabled' :
                ''}
            ${inverted ?
                    'custom-button--inverted' :
                    null}`}

            {...otherProps}
            disabled={isLoading || isComplete || isPay === "paid"}
            onClick={handleClick}
        >
            {icon ?
                <Icon className={`custom-button__icon  ${inverted ?
                    'custom-button__icon--inverted' :
                    null}`} />:
                null
            }
            {
                children
            }
        </button>
    )
};

export default CustomButton;
