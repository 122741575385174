import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SALES_REPORT_BY_CUSTOMER_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/salesbycustomer",
  listPage: "/sales-reports",
  hasDateRange: true,
  hasChart: false,
  tableHasGrandTotal: true,
}

export const SALES_REPORT_BY_CUSTOMER_COLUMN_TEMPLATE = [
  {
    Header: "Customer Name",
    accessor: "customerName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Customer Id",
    accessor: "customerCode",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return null;
      }

      return displayCellValue(value);
    },
  },
  {
    Header: "Total Sales (ETB)",
    accessor: "totalSales",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Sales: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalSales'
  },
  {
    Header: "Cost of Goods Sold (ETB)",
    accessor: "costOfGoodsSold",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return null;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandcostOfGoodsSold'
  },
  {
    Header: "Gross Profit (ETB)",
    accessor: "grossProfit",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Gross Profit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    }
  },

]
