import React, { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useFormContext, Controller } from "react-hook-form";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import "./date-picker-field.styles.scss"

const DatePickerField = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    partNumber,
    fieldItemName,
    validationProps,
    fieldIndex,
    sectionTypeId,
    defaultValue,
    initalValueEmpty,
    disabled,
    sideBySideLabelAndField,
    ...otherProps }) => {
    const name = fieldName + '.' + partNumber + '.' + fieldItemName;
    const { register, formState: { errors }, setFocus, control, setValue } = useFormContext({

    });

    const [initalValueIsEmpty, setInitalValueIsEmpty] = useState(initalValueEmpty)

    const handleOnChange = (date) => {
        if(date){
            setInitalValueIsEmpty(false);
        } else{
         
            setInitalValueIsEmpty(true);
        }
    }

    return (
        <div className={`date-picker ${sideBySideLabelAndField &&  "date-picker--sideBySide"}`}>
            <label
                className="date-picker__label">
                {label}
                {
                    validationProps ?
                        validationProps.required ? <span className="date-picker__label__required"> * </span> :
                            null :
                        null
                }
            </label>
            <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                defaultValue={initalValueIsEmpty ? null :new Date()}
                render={({
                    field: { onChange, onBlur, value, name, ref, setValue },
                }) => {
                    return (
                        <DatePicker
                            className={`date-picker__input 
                                ${errors[fieldName]?.[partNumber]?.[fieldItemName] && "date-picker__input--error"}
                                ${disabled && "date-picker__input--disabled"}
                            `}
                            wrapperClassName="date-picker__date-view"
                            defaultOptions={true}
                            selected={ value && value.getTime()/1000 !== 0 ? value : initalValueIsEmpty ? null : new Date()}
                            dateFormat="dd/MM/yyyy"
                            todayButton="Today"
                            onSelect={(date) => { 
                                onChange(date);
                                handleOnChange(date);
                            }}
                            onChange={(date) => { 
                                onChange(date);
                                handleOnChange(date);
                            }}
                            // onSelect={onChange}
                            // onChange={onChange}
                            disabled = {disabled}
                            {...otherProps}
                        />
                    )
                }}
            />

            {errors?.[fieldName]?.[partNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[partNumber]?.[fieldItemName].message} />}
        </div>

    );
};

export default DatePickerField