import IconAdd from '../../../assets/icons/add.svg';
import IconAddWhite from '../../../assets/icons/add-white.svg';
import IconList from '../../../assets/icons/list.svg';
import IconListWhite from '../../../assets/icons/list-white.svg';
import IconNotifications from '../../../assets/icons/notifications.svg';
import IconSettings from '../../../assets/icons/settings.svg';
import IconLogout from '../../../assets/icons/logout.svg';
import IconHelp from '../../../assets/icons/help.svg';
import IconAccount from '../../../assets/icons/user.svg';
import IconCompany from '../../../assets/icons/office.svg';
import IconRuler from '../../../assets/icons/ruler.svg';
import IconTax from '../../../assets/icons/tax.svg';
import IconLocation from '../../../assets/icons/location.svg';

import { MdOutlineAccountCircle } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import { TbRulerMeasure } from "react-icons/tb";
import { IoLocationOutline } from "react-icons/io5";
import { MdHelpOutline } from "react-icons/md";
import { IoIosPaper } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";


export const NAV_ITEMS = [
    {
        itemName: "Home",
        page: '/home'
    },
    {
        itemName: "Sales",
        dropDownItems: [
            {
                id: 'list_sales_orders',
                img: IconList,
                text: 'Sales Orders',
                modal: false,
                page: '/sales-orders'
            },
            {
                id: 'list_customers',
                img: IconList,
                text: 'Customers',
                modal: false,
                page: '/customers'
            }
        ]
    },
    {
        itemName: "Stock",
        dropDownItems: [
            {
                id: 'items',
                img: IconList,
                text: 'Products',
                modal: false,
                page: '/items'
            },
            {
                id: 'items',
                img: IconList,
                text: 'Stock Transfers',
                modal: false,
                page: '/stock-transfers'
            },
            {
                id: 'items',
                img: IconList,
                text: 'Stock Adjustments',
                modal: false,
                page: '/stock-adjustments'
            },
            {
                id: 'list_work_orders',
                img: IconList,
                text: 'Work Orders',
                modal: false,
                page: '/work-orders'
            }
        ]
    },
    {
        itemName: "Purchasing",
        dropDownItems: [
            {
                id: 'purchase_orders',
                img: IconList,
                text: 'Purchase Orders',
                modal: false,
                page: '/purchase-orders'
            },
            {
                id: 'list_suppliers',
                img: IconList,
                text: 'Suppliers',
                modal: false,
                page: '/suppliers'
            }
        ]
    },
    {
        itemName: "Report",
        dropDownItems: [
            {
                id: 'sales_reports',
                img: IconList,
                text: 'Sales Report',
                modal: false,
                page: '/sales-reports'
            },
            {
                id: 'inventory_reports',
                img: IconList,
                text: 'Stock Report',
                modal: false,
                page: '/inventory-reports'
            },
            {
                id: 'purchase_reports',
                img: IconList,
                text: 'Purchasing Report',
                modal: false,
                page: '/purchase-reports'
            },
           
        ]
    },
]

export const SETTING_NOTFICATION_BAR = [
    {
        itemName: "Settings",
        icon: IconSettings,
        dropDownItems: [
            {
                id: 'accounts',
                icon: MdOutlineAccountCircle,
                text: 'Accounts',
                modal: false,
                page: '/accounts'
            },
            {
                id: 'company',
                icon: FaRegBuilding,
                text: 'Comapny',
                modal: false,
                page: '/company'
            },
            {
                id: 'item_groups',
                icon: BiCategoryAlt,
                text: 'Categories',
                modal: false,
                page: '/item-groups'
            },
            {
                id: 'list_unit_measures',
                icon: TbRulerMeasure,
                text: 'Unit of Measures',
                modal: false,
                page: '/unit-of-measures'
            },
            {
                id: 'list_item_taxes',
                icon: IoNewspaperOutline,
                text: 'Item Taxes',
                modal: false,
                page: '/item-taxes'
            },
            {
                id: 'list_location',
                icon: IoLocationOutline,
                text: 'Branches',
                modal: false,
                page: '/locations'
            },
            {
                id: 'logout',
                icon: MdLogout,
                text: 'logout',
                modal: false,
                page: '/logout',
                breakpoint: true
            },
        ]
    },
]



